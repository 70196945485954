@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-50 bg-opacity-50;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters {
  overflow: hidden;
  border-radius: 15px;
}

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
  overflow: hidden;
  border-radius: 15px;
}

div.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters {
  margin: 0 !important;
}

div.MuiAccordionDetails-root {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
}

div.MuiAccordionSummary-root {
  border-radius: 10px;
  min-height: 6vh !important;
}

div.MuiAccordion-root {
  position: inherit;
}
